<template>
  <van-cell
    title="发票"
    value="不开发票"
    value-class="use"
    is-link
    @click.stop="receipt()"
  />
</template>

<script>
export default {
  name: "ServiceBtn",
  computed: {
    Service() {
      return this.$store.state.Service;
    },
  },
  methods: {
    receipt() {
      this.$dialog
        .confirm({
          title: "开具发票请联系客服微信号",
          message: `<i class='wechat-icon-square'></i>${this.Service.wechat}`,
          confirmButtonText: "复制",
        })
        .then(() => {
          this.$copyText(this.Service.wechat).then(
            (e) => {
              this.$toast("已复制！");
            },
            (e) => {
              this.$toast("复制失败！\n请手动选择并复制");
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>
