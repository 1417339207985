<template>
  <div class="item-operate">
    <!--1：等待买家付款；-->
    <div class="btns-box" v-if="item.status === 1">
      <span class="status-text">待付款</span>
      <van-button type="default" @click="cancelOrder(item.oid)"
        >取消订单</van-button
      >
      <van-button type="warning" @click="toPay(item)">
        付款
        <van-count-down
          @finish="timeOver(item.oid)"
          :time="item.pay_remain * 1000"
          format="mm:ss"
        />
      </van-button>
    </div>
    <!--2：买家已付款（等待卖家发货）；-->
    <div class="btns-box" v-if="item.status === 2">
      <span class="status-text">待发货</span>
      <van-button type="default" @click="cancelOrder(item.oid)"
        >取消订单</van-button
      >
    </div>
    <!--3：卖家已发货；-->
    <div class="btns-box" v-if="item.status === 3">
      <span class="status-text">待收货</span>
      <!-- <ServicesBtn /> -->
      <!-- <van-button :to="{ name: 'Trace', params: { oid: item.oid } }">
        查看物流
      </van-button> -->
      <van-button type="info" @click="receive(item.oid)">确认收货</van-button>
    </div>
    <!--4：订单已完成；-->
    <div class="btns-box" v-if="item.status === 4">
      <span class="status-text">已完成</span>
      <ServicesBtn />
      <van-button type="default" @click="removeOrder(item.oid)"
        >删除订单</van-button
      >
      <van-button @click="buyAgain(item)" type="info">再次购买</van-button>
    </div>
    <!--5：订单被关闭-->
    <div class="btns-box" v-if="item.status === 5">
      <span class="status-text">已关闭</span>
      <!-- <ServicesBtn /> -->
      <van-button type="default" @click="removeOrder(item.oid)"
        >删除订单</van-button
      >
      <van-button type="info" @click="buyAgain(item)">再次购买</van-button>
    </div>
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import ServicesBtn from "@/components/ServicesBtn.vue";
export default {
  name: "OrderOperate",
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  components: { ServicesBtn },
  computed: {
    Service() {
      return this.$store.state.Service;
    },
  },
  created() {},
  methods: {
    toPay(item) {
      this.$router.push({
        name: "Pay",
        params: { data: item },
      });
    },
    removeOrder(oid) {
      this.$dialog
        .confirm({
          message: "确定要删除订单吗？",
        })
        .then(() => {
          this.$ajax
            .post(
              "/order/order/remove/",
              {
                oid: oid,
              },
              "loading"
            )
            .then(() => {
              Bus.$emit("removeOrderOnbus", oid);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    buyAgain(order) {
      if (order.type === 1) {
        this.$ajax
          .post(
            "/user/cart/fromorder/",
            {
              oid: order.oid,
            },
            "loading"
          )
          .then((res) => {
            this.$store.commit("CartBadge", res.cart_num);
            //得到订单内的所有商品，用于购物车默认选中
            let ids = [];
            order.items.forEach((item) => {
              ids.push(item.iid);
            });
            this.$router.push({ name: "Cart", params: { iids: ids } });
          });
      } else {
        this.$router.push({
          name: "BoxDetail",
          params: { id: order.bid },
        });
      }
    },
    timeOver(oid) {
      Bus.$emit("timeOverOnbus", oid);
    },
    cancelOrder(oid) {
      this.$dialog
        .confirm({
          title: "还差一步，宝贝就到手啦！",
          message: "确定取消订单吗？",
          closeOnPopstate: false, //点击取消后还能再弹窗
        })
        .then(() => {
          this.$ajax
            .post(
              "/order/order/cancel/",
              {
                oid: oid,
              },
              "loading"
            )
            .then((res) => {
              Bus.$emit("cancelOrderOnbus", res);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    receive(oid) {
      this.$dialog
        .confirm({
          message: "是否确认收货？",
        })
        .then(() => {
          this.$ajax
            .post(
              "/order/order/confirm/",
              {
                oid: oid,
              },
              "loading"
            )
            .then((res) => {
              Bus.$emit("receiveOnbus", res);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
