<template>
  <div v-if="order" class="prepay ordetail">
    <van-cell-group class="items ordetail-hd" :title="status[order.status - 1]">
      <van-cell center v-for="item in order.items" :key="item.cart_id">
        <template #default>
          <GoodsInOrder :item="item" :type="order.type" :badge="true" />
        </template>
      </van-cell>
      <!-- <van-cell
        class="coupon"
        title="优惠券"
        value="满150减10"
        icon="coupon-o"
        value-class="use"
      /> -->
    </van-cell-group>

    <van-cell-group
      v-if="order.address"
      :title="!order.address ? status[order.status - 1] : ''"
    >
      <van-cell
        class="addr"
        center
        :title="order.address.addr"
        :label="order.address.contact_name + ' ' + order.address.mobile"
        title-class="van-ellipsis"
      />
    </van-cell-group>

    <van-cell-group v-if="order.type === 1" class="money cell-nowrap">
      <van-cell title="商品">
        <template #default> ¥{{ order.price | towDecimalPlaces }} </template>
      </van-cell>
      <van-cell v-if="order.type === 1" title="运费">
        <template #default>
          <span class="labelled" v-if="Math.ceil(order.post_fee) === 0"
            >满500包邮</span
          >
          <span>￥{{ order.post_fee }}</span>
        </template>
      </van-cell>
      <van-cell title="合计">
        <template #default>
          ¥{{ (order.price * 1 + order.post_fee * 1) | towDecimalPlaces }}
        </template>
      </van-cell>
      <van-cell
        title="实付"
        :value="`￥${order.payment}`"
        value-class="price"
      />
    </van-cell-group>

    <van-cell-group v-if="order.type === 2" class="money cell-nowrap">
      <van-cell title="价格">
        <template #default> ¥{{ order.price | towDecimalPlaces }} </template>
      </van-cell>
      <van-cell title="已使用余额">
        <template #default>
          ¥{{ (order.paid_coin / 100) | towDecimalPlaces }}
        </template>
      </van-cell>
      <van-cell
        title="实付"
        :value="`￥${order.payment}`"
        value-class="price"
      />
      <ReceiptBtn />
    </van-cell-group>

    <van-cell-group class="pull-bar order-info">
      <van-cell :title="'订单编号 ' + order.order_no">
        <template #default>
          <van-button
            v-clipboard:copy="order.order_no"
            v-clipboard:success="onCopy"
            v-clipboard:error="onCopyError"
            type="warning"
            plain
            text="复制"
          />
        </template>
      </van-cell>
      <van-cell :title="'下单时间'" :value="order.created_at | formatDate" />
    </van-cell-group>

    <div class="btns-bar">
      <OrderOperate :item="order" />
    </div>
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import GoodsInOrder from "@/components/GoodsInOrder.vue";
import OrderOperate from "@/components/OrderOperate.vue";
import CabinetOperate from "@/components/CabinetOperate.vue";
import ReceiptBtn from "@/components/ReceiptBtn.vue";
export default {
  name: "OrderDetail",
  data() {
    return {
      oid: null,
      type: null,
      order: null,
      status: ["待付款", "已付款", "待收货", "已完成", "已关闭"],
    };
  },
  components: {
    GoodsInOrder,
    OrderOperate,
    CabinetOperate,
    ReceiptBtn,
  },
  created() {
    let params = this.$route.params;
    this.oid = params.oid;
    this.type = params.type;
    this.getOrder();
  },
  mounted() {
    //删除订单
    Bus.$off("removeOrderOnbus");
    Bus.$on("removeOrderOnbus", () => {
      this.$router.push({ name: "Orders" });
    });
    //取消订单
    Bus.$off("cancelOrderOnbus");
    Bus.$on("cancelOrderOnbus", (oid) => {
      this.order.status = 5;
    });
    //确认收货
    Bus.$off("receiveOnbus");
    Bus.$on("receiveOnbus", (oid) => {
      this.order.status = 4;
    });
  },
  methods: {
    onCopy(e) {
      this.$toast.success("复制成功");
    },
    onCopyError(e) {
      this.$toast("复制失败\n请手动选择并复制");
    },
    getOrder() {
      this.$ajax
        .post(
          "/order/order/get/",
          {
            oid: this.oid,
            type: this.type,
          },
          "loading"
        )
        .then((res) => {
          this.order = res.data;
        });
    },
  },
};
</script>
